import React from 'react';
import styled from '@emotion/styled';
import { Flex, Heading, Text } from '@rebass/emotion';
import CmsPage from '../components/Elements/CmsPage';
import { PageProvider } from '../context/PageProvider';
import { Header, Footer } from '../components/PageBlocks';
import Card from '~components/PageBlocks/Card';
import Container from '~components/Elements/Container';

const App = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  padding: 0%;
  max-width: 100vw;
  font-family: ${props => props.theme.fonts.sans};
  /* padding: 0 5vw; */
`;

const Overview = ({ pageContext }) => {
  const { menu, section } = pageContext;

  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <App>
          <Header data={pageContext.header} />
          <Container>
            <Flex
              py={[20, 30, 40, 100, 100]}
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Heading
                as="h1"
                fontSize="44px"
                fontWeight="bold"
                color="blueBlue160"
                textAlign="center"
                style={{ maxWidth: '815px' }}
                mb={['20px', '20px', '20px', '20px', 0]}
              >
                Autism in {section}
              </Heading>

              {menu.items.map((topic, index) => {
                if (topic.page && topic.page._type === 'staticPage') {
                  return null;
                }
                return (
                  <Card key={topic._key} data={topic} type={2} small={true} />
                );
              })}
            </Flex>
          </Container>
          <Footer data={pageContext.footer} />
        </App>
      </PageProvider>
    </CmsPage>
  );
};

export default Overview;
